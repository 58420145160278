import castle from '../img/Carcassonne.jpeg'
import areaheader from '../img/area-header.jpg'
import vineyard from '../img/vineyard2.jpg'
import HeaderImage from '../component/HeaderImage';
import { Stack, Typography } from "@mui/material";
import ParallaxBannerImage from '../component/ParallaxBannerImage';


export default function Area() {

    const Title = ({ children }) => (
        <Typography
            component="h1"
            sx={{
                font: "var(--h1)",
                fontSize: { xs: '3rem', md: '4rem' },
                color: "black"
            }}
        >
            {children}
        </Typography>
    );


    const NormalText = ({ children }) => (
        <Typography
            component="p"
            sx={{
                font: "var(--p)",
                fontSize: { xs: '1.2rem', md: '1.6rem' },
                color: "black",
                px: { xs: 3, md: 10 },
                maxWidth: '1200px'
            }}
        >
            {children}
        </Typography>
    );

    return (
        <>
            <HeaderImage header='The Area' image={areaheader} height='400px' />

            <Stack
                spacing={4}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                    textAlign: 'center',
                    backgroundColor: '#F6A99D',
                    py: 10,
                }}
            >
                <Title>
                    Carcassonne
                </Title>

                <NormalText>
                    Carcassonne, a hilltop town in southern France’s Languedoc area, is famous for its medieval citadel,
                    La Cité, with numerous watchtowers and double-walled fortifications.
                    The first walls were built in Gallo-Roman times, with major additions made in the 13th and 14th centuries.
                    Château Comtal, a 12th-century castle within the Cité, offers archaeological exhibits and a tour of the inner ramparts.
                    (Jack also wants you all to know that is a boardgame too).
                </NormalText>

                <Title>
                    Canal du Midi
                </Title>

                <NormalText>
                    The Canal du Midi is a UNESCO world heritage site.
                    This 360km network of navigable waterways link the Mediterranean and the Atlantic.
                    It is one of the most remarkable feats of civil engineering in modern times and paved the way for the Industrial Revolution.
                    Floating along on the water, you can leave all the stress, emails and queues behind you to really enjoy this
                    'slow' mode of travelling. Whether you take a bike ride, along the old towpaths, or you sail like a captain in a hired boat,
                    or you go for a guided walk, it is a time of peace for everyone.
                </NormalText>

            </Stack>

            <ParallaxBannerImage image={castle} />

            <Stack
                spacing={4}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                    textAlign: 'center',
                    backgroundColor: '#F6A99D',
                    py: 10,
                }}
            >
                <Title>
                    Vineyards
                </Title>

                <NormalText>
                    The château is located in the heart of  the Minervois wine region, which is part of the greater wine region of
                    Languedoc - a massive area of sun-baked vineyards, almost 3 times the size of Bordeaux, that runs along the Mediterranean coast.
                    There are over 20 vineyards within the Greater Carcassonne area, many of which offer tours and tastings.
                    Château Saint Jacques d'Albas is a 25 minute walk/5 minute drive from the venue and offers tours & tastings.
                    We would also recommend visiting Limoux, the home of Blanquette de Limoux, which is considered to be the first
                    sparkling white wine produced in France, created long before the Champagne region became world-renowned for the
                    sparkling wine Champagne.
                </NormalText>
            </Stack>

            <ParallaxBannerImage image={vineyard} />
        </>
    );
}