import faqsheader from '../img/faqs-header.jpg'
import HeaderImage from "../component/HeaderImage";
import FaqsAccordion from "../component/FaqsAccordion";

export default function Faqs() {

    const faqsinput = [
        {
            question: 'What will the weather be like this time of year?',
            answer: `After INTENSE research by Fee, and past weather information, we hope that the weather will be around 24 degrees and sunny, with lows of 15 degrees in the evening.
            \nThe majority of events will be taking place in an outdoor garden; we recommend avoiding stilettos to avoid sinking into the grass. There is an indoor space in case of rain.`
        },
        {
            question: 'What if I want to extend my stay?',
            answer: `Château Villarlong offer a 10% discount to any guests who want to stay for longer than 3 nights. If this is something you are interested in doing, please let us know and we can coordinate with the venue.`
        },
        {
            question: 'Should I bring a gift to your destination wedding?',
            answer: `Your presence is the perfect present. We understand that you’ve incurred costs to attend our celebration and we do not expect anything in addition to this.
            \nHowever, if you are feeling extra generous,  a small contribution to our honeymoon would be greatly appreciated.`
        },
        {
            question: 'Can I bring a plus one?',
            answer: `Unfortunately, we  are limited on numbers to allow us to accomodate everyone at the château. We will only be able to accommodate those listed on your wedding invitation.`
        },
    ];

    return (
        <>
            <HeaderImage header="FAQs" image={faqsheader} height='400px' />
            <FaqsAccordion faqs={faqsinput} />
        </>
    )
}