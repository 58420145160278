import './Home.css';
import villarlong from '../img/villarlong.JPG'
import day1 from '../img/day1.jpg'
import day2 from '../img/day2.jpg'
import day3 from '../img/day3.jpg'
import day4 from '../img/day4.jpg'
import backgroundImage from '../img/villarlong-background.jpg'
import Invitation from '../component/Invitation';
import ParallaxBannerImage from '../component/ParallaxBannerImage';
import { Stack, Typography } from "@mui/material";
import CardSlider from '../component/CardSlider';
import ScheduleCard from '../component/ScheduleCard';
import HeaderImage from '../component/HeaderImage';

function Home({ }) {

    const schedule = [
        {
            image: day1,
            header: "Day One",
            subHeader: `Friday 30th May\nWelcome Dinner`,
            description: "Once you arrive at the château, please join us the night before the wedding for a sit down welcome dinner.",
            dressCode: `Dress code: Cocktail\nLadies: Maxi/Midi Dress\nMen: Trousers & Shirt`
        },
        {
            image: day2,
            header: "Day Two",
            subHeader: `Saturday 31st May\nThe Big Day`,
            description: "Take the morning to relax, enjoy the grounds or sunbathe, before joining us in the late afternoon for our wedding ceremony. The ceremony will be followed by a Vin d’Honneur and an evening full of food, wine and dancing.",
            dressCode: `Dress code: Black Tie\nLadies: Long evening wear\nMen: Tuxedos preferred`
        },
        {
            image: day3,
            header: "Day Three",
            subHeader: `Sunday 1st June\nPool Party`,
            description: "Late morning, we will be hosting a brunch for all guests, followed by a sports day. Then join us in the evening for a BBQ & Pool party, where we dance the night away (did someone say circle?). The brunch, BBQ & refreshments will be provided.",
            dressCode: `Dress code: Casual\nLadies: Summer Dresses\nMen: Shorts & Shirt`
        },
        {
            image: day4,
            header: "Day Four",
            subHeader: `Monday 2nd June\nDeparture`,
            description: "Check out is at 11am, feel free to enjoy the pool until you are ready to leave.",
        },
    ];

    const NormalText = ({ children }) => (
        <Typography
            component="p"
            sx={{
                font: "var(--p)",
                fontSize: { xs: '1.4rem', md: '2rem' },
                color: "white"
            }}
        >
            {children}
        </Typography>
    );

    return (
        <>
            <HeaderImage header="Jack + Fee" image={backgroundImage} height='100dvh'>
                    <NormalText>MEET US AT THE CHÂTEAU</NormalText>
                    <NormalText>30th May - 2nd June 2025</NormalText>
            </HeaderImage>

            <Invitation />

            <ParallaxBannerImage image={villarlong} />

            <Stack
                spacing={3}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                    textAlign: 'center',
                    backgroundColor: '#A3B899',
                    py: 10,
                    px: { xs: 0, md: 10 }
                }}
            >
                <Typography
                    component="h1"
                    sx={{
                        font: "var(--h1)",
                        fontSize: { xs: '2.5rem', md: '4rem' },
                        color: "black"
                    }}
                >
                    Wedding Weekend
                </Typography>

                <CardSlider>
                    {
                        schedule.map((day, index) => (
                            <ScheduleCard
                                key={index}
                                props={day}
                            />
                        ))
                    }
                </CardSlider>
            </Stack>
        </>
    );
}

function openInNewTab(url) {
    window.open(url, '_blank');
}

export default Home;