import carcassonneCard from '../img/carcassonne-card.jpg'
import perpignan from '../img/perpignan.jpg'
import toulouse from '../img/toulouse.jpg'
import bezier from '../img/Beziers.jpg'
import trains from '../img/Trains.png'
import taylorTravel from '../img/taylor-travel.jpg'
import HeaderImage from "../component/HeaderImage";
import { Stack, Typography } from "@mui/material";
import ParallaxBannerImage from '../component/ParallaxBannerImage';
import CardSlider from '../component/CardSlider'
import FlightCard from '../component/FlightCard'

export default function Logistics() {

    const Title = ({ children }) => (
        <Typography
            component="h1"
            sx={{
                font: "var(--h1)",
                fontSize: { xs: '3rem', md: '4rem' },
                color: "black"
            }}
        >
            {children}
        </Typography>
    );


    const NormalText = ({ children }) => (
        <Typography
            component="p"
            sx={{
                font: "var(--p)",
                fontSize: { xs: '1.2rem', md: '1.6rem' },
                color: "black",
                px: { xs: 3, md: 10 },
                maxWidth: '1200px'
            }}
        >
            {children}
        </Typography>
    );

    const flights = [
        {
            image: carcassonneCard,
            header: "Carcassonne (CCF)",
            distance: "20 minute drive to the venue",
            subHeader: `This is the nearest airport and where we recommend you fly to. In summer 2025 there are Ryanair flights from Bournemouth, East Midlands, London Stanstead & Manchester.`,
            description: `Ryanair flights have been released and the schedule is as follows.`,
            schedule: `Summer 2025 schedule:\nBournemouth: Wednesday & Saturday\nEast Midlands: Monday & Friday\nLondon Stanstead: Daily\nManchester: Tuesday, Thursday, Saturday, Sunday`
        },
        {
            image: toulouse,
            header: "Toulouse (TLS)",
            distance: "45 min train to Carcassonne + 20 minute drive to venue",
            subHeader: `This is the closest airport with multiple daily flight options. In summer 2025 there are Ryanair flights from Birmingham & London Stanstead. Easyjet flights from Bristol & London Gatwick. BA Flights from London Heathrow`,
            description: "All flights have been released and the schedule is as follows:",
            schedule: 'Summer 2025 schedule:\nBirmingham: Tuesday & Saturday\nLondon Stanstead : Daily\nBristol: Monday, Wednesday, Friday, Sunday\nLondon Gatwick: Monday,  Thursday,  Friday & Sunday\nLondon Heathrow: Daily'
        },
        {
            image: bezier,
            header: "Béziers (BZR)",
            distance: "45 min train to Carcassonne + 20 minute drive to venue",
            subHeader: `In summer 2025 there are Ryanair flights from Bristol, London Stanstead, London Luton, and Manchester.`,
            description: "Ryanair flights have been released and the schedule is as follows:",
            schedule: `Summer 2025 schedule:\nBristol: Monday, Wednesday & Saturday\nLondon Stanstead: Monday, Wednesday & Friday\nLondon Luton: Tuesday, Thursday & Sunday\nManchester: Tuesday, Thursday & Saturday`
        },
        {
            image: perpignan,
            header: "Perpignan (PGF)",
            distance: "1h30 train to Carcassonne + 20 minute drive to venue",
            subHeader: `In summer 2025 there are Ryanair flights from Leeds Bradford & London Stanstead.`,
            description: "Ryanair flights have been released and the schedule is as follows:",
            schedule: `Summer 2025 schedule:\nLeeds Bradford: Wednesday & Sunday\nLondon Stanstead: Monday, Tuesday, Wednesday, Friday, Sunday`
        },
    ]

    return (
        <>
            <HeaderImage header="Logistics" image={trains} height='400px' />

            <Stack
                spacing={4}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                    textAlign: 'center',
                    backgroundColor: '#94A3C4',
                    py: 10,
                }}
            >
                <Title>Flights</Title>

                <NormalText>
                    We would recommend that you fly to Carcassonne, which is the closest airport, a 20 minute drive from the château.
                    Alterntaively, there are 3 other airports nearby.
                    The majority of flights are operated by Ryanair.

                    <br />
                    <strong>
                        All flights are now available!
                        <br />
                        If you are coming from London and joining us for the whole weekend, we would recommend <a href='https://www.ryanair.com/gb/en/trip/flights/select?adults=1&teens=0&children=0&infants=0&dateOut=2025-05-30&dateIn=2025-06-02&isConnectedFlight=false&discount=0&promoCode=&isReturn=true&originIata=STN&destinationIata=CCF&tpAdults=1&tpTeens=0&tpChildren=0&tpInfants=0&tpStartDate=2025-05-30&tpEndDate=2025-06-02&tpDiscount=0&tpPromoCode=&tpOriginIata=STN&tpDestinationIata=CCF'>these flights</a>.
                    </strong>
                </NormalText>

                <CardSlider>
                    {
                        flights.map((flight, index) => (
                            <FlightCard
                                key={index}
                                props={flight}
                            />
                        ))
                    }
                </CardSlider>

            </Stack>

            <ParallaxBannerImage image={taylorTravel} />

            <Stack
                spacing={4}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                    textAlign: 'center',
                    backgroundColor: '#94A3C4',
                    py: 10,
                }}
            >
                <Title>Trains</Title>

                <NormalText>
                    If you are environmentally concious (@Ev) it is possible to get to the venue via train.
                    You can take the Eurostar from London St Pancras International to Paris Gare du Nord.
                    From Paris, there are various routes available, typically requiring one change and avergarging a 6-hour travel time.
                    Eurostar tickets are released 120 days in advance (Thursday 30th January 2025), the earlier you book, the better value the fares.
                </NormalText>

                <Title>Getting to the Venue</Title>

                <NormalText>
                    Unfortunately, it is not possible to get to the venue via public transport from Carcassonne airport or train station.
                    We would recommend hiring a car, there are various suppliers based both  in the city centre and at Carcassonne airport,
                    and there is ample free parking at the venue. We have previously used Avis, and would recommend them.
                    If you book in advance you can expect to pay around £180 for the weekend (£36 per person when split between 5).
                    Alternatively Taxis are available, but will need to be booked in advance. A taxi from the airport is approx. 35 euro each way.
                    Finally, if there are lots of people getting the recommended flights to Carcassone we will look to arrange lift shares & shuttles.
                    If you fly into one of the other airports, they are all connected to Carcassonne via train
                </NormalText>
            </Stack>

        </>
    )
}