import accommodationheader from '../img/accommodation-header.JPG';
import tennis from '../img/tennis-accom2.jpg'
import HeaderImage from "../component/HeaderImage";
import { Stack, Typography } from "@mui/material";
import ParallaxBannerImage from '../component/ParallaxBannerImage';

export default function Accommodation() {

    const NormalText = ({ children }) => (
        <Typography
            component="p"
            sx={{
                font: "var(--p)",
                fontSize: { xs: '1.2rem', md: '1.6rem' },
                color: "black",
                px: { xs: 3, md: 10 },
                maxWidth: '1200px'
            }}
        >
            {children}
        </Typography>
    );

    return (
        <>
            <HeaderImage header="Accommodation" image={accommodationheader} height='400px' />

            <Stack
                spacing={4}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                    textAlign: 'center',
                    backgroundColor: '#A3B899',
                    py: 10,
                }}
            >
                <NormalText>
                    The entire Château is reserved for our guests from Friday 30th May - Monday 2nd June.
                    We would love for you to join us and stay for the entire weekend!
                    A contribution towards the cost of your accommodation for all 3 nights is 200 euros per person.
                    Please confirm if you will be staying with us at the Château when you RSVP and we will allocate rooms accordingly.
                </NormalText>

                <NormalText>
                    The accommodation is made up of 2 and 3 bed apartments. All apartments come with bath & pool towels, robes and kitchens.
                    The fridges will be pre-stocked with basic pantry items.
                    For anything further there are supermarkets on the way from the airport.
                    Closer to the time, you will be contacted to coordinate payment & breakfast preferences.
                </NormalText>
                
                <NormalText>
                    Bring your speedos & tennis whites - there is a swimming pool and tennis court that you are welcome to enjoy during your stay
                </NormalText>

            </Stack>

            <ParallaxBannerImage image={tennis} />
        </>
    )
}