import { Button, Stack, Typography } from "@mui/material";
import SquareIcon from '@mui/icons-material/Square';

export default function Invitation() {

    const DiamondIcon = () => <SquareIcon
        sx={{
            fontSize: '10px',
            transform: 'rotate(45deg)',
            scale: '1, 1'
        }}
    />

    const Title = ({ children }) => (
        <Typography
            component="h1"
            sx={{
                font: "var(--h1)",
                fontSize: { xs: '3rem', md: '4rem' },
                color: "black"
            }}
        >
            {children}
        </Typography>
    );


    const NormalText = ({ children }) => (
        <Typography
            component="p"
            sx={{
                font: "var(--p)",
                fontSize: { xs: '1.4rem', md: '2rem' },
                color: "black"
            }}
        >
            {children}
        </Typography>
    );


    return (
        <Stack
            spacing={3}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{
                textAlign: 'center',
                backgroundColor: '#A3B899',
                padding: 10
            }}
        >
            <Title>Let's Celebrate</Title>

            <NormalText>Together with their parents,<br />
                <strong>Fiona Taylor & Jack Pickford</strong><br />
                are delighted to invite you to soak up the sun and raise a<br />
                few glasses in celebration of their marriage.</NormalText>

            <DiamondIcon />

            <NormalText>Friday 30th May - Monday 2nd June 2025</NormalText>

            <br />

            <Title>Where</Title>

            <NormalText>
                Château Villarlong<br />
                11600 Villarzel-Cabardes<br />
                France
            </NormalText>

            <DiamondIcon />

            <NormalText component="p">20 minutes drive/17.5km from Carcassonne Airport</NormalText>

            <Button
                variant="contained"
                disableElevation
                onClick={() => window.open('https://maps.app.goo.gl/kDYJ5cXeXRZ1mR1R7', '_blank')}
                sx={{
                    color: 'black',
                    borderColor: 'black',
                    backgroundColor: 'white',
                    font: "var(--p)",
                    fontSize: { xs: '1rem', md: '1.5rem' },
                    '&:hover': {
                        backgroundColor: 'black',
                        color: 'white',
                    },
                }}
            >
                View Map
            </Button>
        </Stack>
    );
}